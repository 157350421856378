import React from 'react'
import styled from 'styled-components'

const OverlayWrapper = styled.div`
  position: relative;
  max-width: 800px;
  margin: 0 auto 32px;
  height: 250px;
  background-size: cover;
  background-position: center;

  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
    text-transform: uppercase;
    line-height: 40px;
  }

  @media screen and (min-width: 500px) {
    .overlay-text {
      font-size: 48px;
      line-height: 48px;
      width: 500px;
    }
  }
`

const HeadingOverlay = ({ imageUrl, heading }) => {
  return (
    <OverlayWrapper style={{ backgroundImage: `url(${imageUrl})` }}>
      <h2 className="overlay-text">{heading}</h2>
    </OverlayWrapper>
  )
}

export default HeadingOverlay;
