import { graphql, useStaticQuery } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import FilterNav from '../../components/FilterNav'
import HeadingOverlay from '../../components/HeadingOverlay'
import NoResultsText from '../../components/NoResultsText'
import { PageContext } from '../../context/ApplicationState'

const TopProductsSection = styled.section`
  .multi-column-section {
    column-count: 1;
    column-gap: 0;
    line-height: 1.5;
    text-align: center;
    font-size: 16px;
  }

  .center-list {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    font-size: 18px;
    margin-left: 16px;
  }

  .center-list > ol {
    list-style-position: inside;
    text-align: left;
    padding: 0;
  }

  .center-list > ol > li {
    margin: 8px;
    list-style: decimal;
  }

  .heading-no-bg {
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  padding-top: var(--section-padding);

  > .label {
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 18px;
  }
  > .rank {
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  > .names {
    font-size: 14px;
    margin: auto;
    max-width: 800px;
    line-height: 18px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.darkText};
  }

  .gatsby-image-wrapper {
    width: var(--image-size) !important;
    height: var(--image-size) !important;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 500px) {
  .multi-column-section > ul, .multi-column-section > ul > li {
    list-style: circle;
  }

  .multi-column-section {
    column-count: 2;
    column-gap: 30px;
    text-align: left;
  }
}

@media screen and (min-width: ${props => props.theme.responsive.medium}) {
  .multi-column-section {
    column-count: 3;
  }
}

@media screen and (min-width: ${props => props.theme.responsive.large}) {
--image-size: 700px;
--object-size: 156px;
}
`

const TopProducts = () => {
  const data = useStaticQuery(graphql`
    query {
    allContentfulTopProducts(
        filter: { title: { eq: "Top Products" } }
      ) {
        edges {
          node {
            date: date(formatString: "MMMM YYYY")
            top20Products {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top5Highlight {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top5Contour {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top3Illuminator {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top3Bronzers {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top3CreamCorrectors {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top5Brushes {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top10LipCheek {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
            top10Eyeshadow {
               title
               description
               date
               showAsSection
               heroImage {
                 fluid(
                   sizes: "(max-width: 1000px)"
                   quality: 70
                 ) {
                     ...GatsbyContentfulFluid_withWebp
                 }
               }
               us {
                  childMarkdownRemark {
                    html
                  }
               }
               ca {
                  childMarkdownRemark {
                    html
                  }
               }
            }
          }
        }
      }
    }
  `)

  const topProductData = data.allContentfulTopProducts.edges[0].node

  const allCategories = Object.values(topProductData)
  allCategories.shift() // remove the date from the categories array
  const displayCategories = allCategories.filter(section => section.showAsSection === true)

  const { pageState, setPageState } = useContext(PageContext)

  useEffect(() => {
    setPageState(pageState => ({
      ...pageState,
      hasStickySiteNav: true,
      hasHeroSection: false,
    }))
  }, [])

  useEffect(() => {
    document.querySelector('body').classList.add('has-light-header')
    return () =>
      document.querySelector('body').classList.remove('has-light-header')
  }, [])

  const [country, setCountry] = useState(1)

  return (
    <div className="honor-roll-container wrap text-center">
      {displayCategories ? (
        <>
          <h2 className="headline--small">{topProductData.date} Top Products</h2>

          {/*
          <TopProductsSection>
            <FilterNav className="country">
              <button
                className={
                  country === 1 ? 'is-active' : undefined
                }
                onClick={() => setCountry(1)}
              >
                United States
              </button>
              <button
                className={
                  country === 2 ? 'is-active' : undefined
                }
                onClick={() => setCountry(2)}
              >
                Canada
              </button>
            </FilterNav>
          </TopProductsSection>
          */}

          {
            displayCategories.map(section => (
              <TopProductsSection key={section.title} className={section.title}>
                <div>
                  {section.heroImage ? (
                    <HeadingOverlay imageUrl={section.heroImage.fluid.src} heading={section.title} />
                  ) : (<h2 className="heading-no-bg">{section.title}</h2>)
                  }
                </div>
                {country === 1 ?
                  <>
                    <div style={{ margin: 'auto', textTransform: 'uppercase', maxWidth: '700px' }}>
                      <div className="center-list" style={{ marginTop: '14px' }}
                        dangerouslySetInnerHTML={{
                          __html: section.us.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  </>
                  :
                  <>
                    <div style={{ margin: 'auto', textTransform: 'uppercase', maxWidth: '700px' }}>
                      <div className="center-list" style={{ marginTop: '14px' }}
                        dangerouslySetInnerHTML={{
                          __html: section.ca.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  </>
                }
              </TopProductsSection>
            ))
          }

        </>
      ) : <NoResultsText>
        We haven't added any "Top Products" yet 😢
      </NoResultsText>
      }
    </div>
  )
}

export default TopProducts
